import store from "@/stores";
import {
  collection,
  collectionGroup,
  getFirestore,
  doc,
  query,
  orderBy,
  where,
  limit,
} from "firebase/firestore";

export default {
  db(term) {
    let path = store.getters.getPath;
    if (term !== undefined) {
      path += "/" + term;
    }

    return collection(getFirestore(), path);
  },
  dbDoc(term, id) {
    let path = store.getters.getPath;
    if (term !== undefined) {
      path += "/" + term;
    }

    return doc(getFirestore(), path, id);
  },
  dbFaculties(term) {
    const school = store.getters.getCurrentUser.school;

    let path = `schools/${school}/faculties`;
    if (term !== undefined) {
      path += "/" + term;
    }
    return collection(getFirestore(), path);
  },
  requests() {
    return query(
      collection(getFirestore(), "requests"),
      orderBy("createdAt", "desc"),
      where("completed", "==", ""),
      where("ignored", "==", false)
    );
  },
  request(id) {
    return doc(getFirestore(), "requests", id);
  },
  materialRequests() {
    return query(
      collectionGroup(getFirestore(), "material-requests"),
      orderBy("date", "desc"),
      where("status", "==", "pending")
    );
  },
  audits() {
    const phone = store.getters.getCurrentUser.phoneNumber;

    return collection(getFirestore(), `auth/${phone}/audits`);
  },
  myUser() {
    console.log('user', store.getters.getCurrentUser);
    const userId = store.getters.getCurrentUser.userId;
    console.log(userId);
    if (!userId) {
      throw new Error("User id not provided");
    }
    return doc(getFirestore(), "users", userId);
  },
  user(id) {
    return doc(getFirestore(), "users", id);
  },
  myAuth() {
    const phone = store.getters.getCurrentUser.phoneNumber;

    return doc(getFirestore(), "auth", phone);
  },
  deviceTokens() {
    const phone = store.getters.getCurrentUser.phoneNumber;
    return collection(getFirestore(), `auth/${phone}/devices`);
  },
  tokenQuery(token) {
    const phone = store.getters.getCurrentUser.phoneNumber;

    return query(
      collection(getFirestore(), `auth/${phone}/devices`),
      where("token", "==", token),
      limit(1)
    );
  },
};
