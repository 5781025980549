import { getMessaging, getToken } from "firebase/messaging";
import Vue from "vue";
import axios from "axios";
import loadCourses from "@/stores/functions/load-courses";
import loadDepartments from "@/stores/functions/load-departments";
import loadReps from "@/stores/functions/load-reps";

const state = {
  departments: [],
  courses: [],
  stats: {},
  reps: [],
};

const mutations = {
  updateDepartments(state, data) {
    state.departments = data;
  },
  updateCourses(state, data) {
    state.courses = data;
  },
  updateStats(state, data) {
    state.stats = data;
  },
  updateReps(state, data) {
    state.reps = data;
  },
};

const getters = {
  getDepartments(state) {
    return state.departments;
  },
  getCourses(state) {
    return state.courses;
  },
  getStats(state) {
    return state.stats;
  },
  getReps(state) {
    return state.reps;
  },
};

const actions = {
  async setUp(context) {
    loadCourses();
    loadDepartments();
    loadReps();
    await context.dispatch("loadRecords");
    await context.dispatch("setUpNotifications");
  },
  async loadRecords(context) {
    const faculty = context.getters.getCurrentUser.faculty;
    if (!faculty) return;
    let loader = Vue.$loading.show();
    try {
      const res = await axios.get(
        `https://scoolnetwork.com/api/v2/myclass/stats/${faculty}`
      );
      context.commit("updateStats", res.data);
    } catch (e) {}
    loader.hide();
  },
  async setUpNotifications(context) {
    const messaging = getMessaging();
    const permission = await Notification.requestPermission();
    if (permission !== "granted") return;
    getToken(messaging, {
      vapidKey:
        "BOxmabLcFczhoj4PyDJsKYtUbvxkDOLPI6IoAYYMd6LJkgpTbBu00Z-ZTpF9GCcOaNiK4i5t_mjQ17b-OsWaTi0",
    })
      .then((currentToken) => {
        if (!currentToken) {
          return;
        }
        // save token to database
        context.dispatch("storeDeviceToken", currentToken);
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
