<template>
  <section id="content_wrapper">
    <div id="canvas-wrapper">
      <canvas id="demo-canvas"></canvas>
    </div>
    <!-- Content -->
    <section id="content">
      <!-- Login Form -->
      <div class="allcp-form theme-primary mw320" id="login">
        <div class="bg-primary mw600 text-center mb20 br3 pt15 pb10">
          <!-- <img src="assets/img/logo.png" alt=""/> -->
          <div class="logo-widget">
            <router-link class="logo-image mtn" to="/">
              <!-- <img src="assets/img/logo.png" alt="" class="img-responsive"> -->
            </router-link>
            <div class="logo-slogan mtn">
              <div>MyClass<span class="text-info"></span></div>
            </div>
          </div>
        </div>
        <div class="panel mw320">
          <form @submit.prevent="login">
            <div class="panel-body pn mv10">
              <div class="section">
                <label for="username" class="field prepend-icon">
                  <input
                    type="text"
                    name="phone"
                    id="username"
                    class="gui-input"
                    v-model="phoneNumber"
                    placeholder="Phone Number"
                    :disabled="codeInput"
                    required
                  />
                  <span class="field-icon">
                    <i class="fa fa-phone"></i>
                  </span>
                </label>
              </div>
              <div id="captcha-box"></div>
              <!-- /section -->
              <div class="section" v-if="codeInput">
                <label for="password" class="field prepend-icon">
                  <input
                    type="text"
                    name="password"
                    id="password"
                    class="gui-input"
                    autocomplete="false"
                    v-model="otpCode"
                    placeholder="Code"
                    required
                  />
                  <span class="field-icon">
                    <i class="fa fa-lock"></i>
                  </span>
                </label>
              </div>
              <!-- /section -->
              <div class="section">
                <button
                  type="submit"
                  class="btn btn-bordered btn-primary pull-right"
                  v-if="!codeInput"
                  :disabled="loading"
                  id="sign-in-button"
                >
                  Send OTP
                </button>
                <button
                  type="submit"
                  class="btn btn-bordered btn-primary pull-right"
                  v-else
                  :disabled="loading"
                >
                  Login
                </button>
              </div>
              <!-- /section -->
            </div>
            <!-- /Form -->
          </form>
        </div>
        <!-- /Panel -->
      </div>
      <!-- /Spec Form -->
    </section>
    <!-- /Content -->
  </section>
</template>

<script>
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

export default {
  name: "LoginApp",
  created() {
    document.body.className = "utility-page sb-l-c sb-r-c";
  },
  destroyed() {
    document.body.className = "sb-top sb-top-lg";
  },
  mounted() {
    window.BGcanvas.init();
    const auth = getAuth();
    this.recaptchaVerifier = new RecaptchaVerifier(
      "captcha-box",
      {
        size: "invisible",
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.codeInput = true;
          this.loading = false;
        },
      },
      auth
    );
  },
  data() {
    return {
      recaptchaVerifier: null,
      codeInput: false,
      loading: false,
      phoneNumber: "",
      otpCode: "",
      otpResult: null,
    };
  },
  methods: {
    async login() {
      if (!this.codeInput) {
        let number = "+234" + this.phoneNumber.replace(/^0|\+?234/, "");
        return this.sendOTP(number);
      }

      this.loading = true;
      try {
        let result = await this.otpResult.confirm(this.otpCode);
      } catch (e) {
        alert("Invalid OTP code");
      }
      this.loading = false;
    },
    async sendOTP(number) {
      const auth = getAuth();
      this.loading = true;
      this.otpResult = await signInWithPhoneNumber(
        auth,
        number,
        this.recaptchaVerifier
      );
    },
  },
};
</script>
