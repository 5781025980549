<style>
.r-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.r-actions a {
  margin: 0 7px;
}
</style>
<template>
  <tr :class="classes">
    <td :title="request.user.id">{{ index + 1 }}</td>
    <td>
      <a :href="request.downloadLink" target="_blank"> Attachment </a>
    </td>
    <td>{{ request.user.school }} - {{ request.user.faculty }}</td>
    <td>{{ request.courseCode }}</td>
    <td>
      <span>{{ request.user.level }}</span>
    </td>
    <td>
      {{ request.createdAt }}
    </td>
    <td class="r-actions">
      <a class="btn btn-success" href="javascript:" @click.prevent="completed()"
        ><i class="fa fa-check"></i
      ></a>
      <a class="btn btn-danger" href="javascript:" @click.prevent="ignore()"
        ><i class="fa fa-trash"></i
      ></a>
    </td>
  </tr>
</template>

<script>
import { serverTimestamp, updateDoc } from "firebase/firestore";
import FB from "@/services/firestore";
import { Notyf } from "notyf";

const notyf = new Notyf();

export default {
  name: "RequestMaterialItem",
  props: {
    request: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    classes: {
      type: Object,
      required: false,
    },
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    completed() {
      const request = this.request;
      updateDoc(request.ref, {
        completed: "Approved",
        completedBy: this.$store.getters.getCurrentUser.phoneNumber,
        at: serverTimestamp(),
      })
        .then(() => {
          notyf.success("Material approved");
        })
        .catch((error) => {
          notyf.error(error.message);
        });
    },
    ignore() {
      const request = this.request;

      console.log(request.ref);

      updateDoc(request.ref, {
        ignored: true,
        ignoredBy: this.$store.getters.getCurrentUser.phoneNumber,
        at: serverTimestamp(),
      })
        .then(() => {
          notyf.success("Material rejected");
        })
        .catch((error) => {
          notyf.error(error.message);
        });
    },
  },
};
</script>
