<style>
.r-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.r-actions a {
  margin: 0 7px;
}
</style>
<template>
  <tr :class="classes">
    <template v-if="isLoading">
      <td colspan="9" class="text-center">
        <i class="fa fa-spinner fa-spin fa-5x"></i>
      </td>
    </template>
    <template v-show="!isLoading">
      <td title="See User's Courses">
        <a href="javascript:" @click.prevent="visitUser()" target="_blank">{{
          index + 1
        }}</a>
      </td>
      <td>
        {{ request.comment || "N/A" }}
      </td>
      <td>
        <a :href="request.file.url" target="_blank"
          ><img
            v-if="request.image"
            :src="request.file.url"
            width="50"
            height="50"
            alt="Attachment"
          />
          <span v-else>Attachment</span>
        </a>
      </td>
      <td>{{ request.user.school }} - {{ request.user.faculty }}</td>
      <RequestDepartment :user="request.user" />
      <td>
        <span>{{ request.user.level }}</span>
      </td>
      <td>
        {{ request.createdAt }}
      </td>
      <td class="r-actions">
        <a
          class="btn btn-success"
          href="javascript:"
          @click.prevent="completed()"
          ><i class="fa fa-check"></i
        ></a>
        <a
          class="btn btn-warning"
          href="javascript:"
          @click.prevent="switchUser()"
          ><i class="fa fa-refresh"></i
        ></a>
        <a class="btn btn-danger" href="javascript:" @click.prevent="ignore()"
          ><i class="fa fa-trash"></i
        ></a>
      </td>
    </template>
  </tr>
</template>

<script>
import RequestDepartment from "@/views/requests/components/RequestDepartment.vue";
import {getDoc, serverTimestamp, updateDoc} from "firebase/firestore";
import FB from "@/services/firestore";
import { Notyf } from "notyf";

const notyf = new Notyf();

export default {
  name: "RequestItem",
  components: { RequestDepartment },
  props: {
    request: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    classes: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    completed() {
      const request = this.request;
      updateDoc(FB.request(request.id), {
        completed: "Your class data has been updated.",
        completedBy: this.$store.getters.getCurrentUser.phoneNumber,
        at: serverTimestamp(),
      })
        .then(() => {
          notyf.success("Request completed successfully");
        })
        .catch((error) => {
          notyf.error(error.message);
        });
    },
    ignore() {
      const request = this.request;

      updateDoc(FB.request(request.id), {
        ignored: true,
        ignoredBy: this.$store.getters.getCurrentUser.phoneNumber,
        at: serverTimestamp(),
      })
        .then(() => {
          notyf.success("Request ignored successfully");
        })
        .catch((error) => {
          notyf.error(error.message);
        });
    },
    visitUser() {
      this.switchUser(true);
    },
    switchUser(visit) {
      const request = this.request;
      this.isLoading = true;

      updateDoc(FB.myAuth(), {
        school: request.user.school || this.user.school,
        faculty: request.user.faculty || this.user.faculty,
        current: request.id,
      })
        .then(() => {
          notyf.success(`Faculty switched to ${request.user.faculty}`);
          if (visit) {
            window.open(
              `/courses?userId=${request.user.id}`,
              "_blank",
              "noopener"
            );
          }
        })
        .catch((error) => {
          notyf.error(error.message);
        })
        .finally(() => {
          this.isLoading = false;
        });

      updateDoc(FB.request(request.id), {
        admin: this.user,
      }).then(
        () => {},
        (error) => {
          console.log("here");
          console.log(error);
        }
      );

      if (!this.user.id) return;
      const data = {
        school: request.user.school,
        faculty: request.user.faculty,
        department: request.user.department,
        level: request.user.level,
        semester: request.user.semester,
      };
      console.log('before', data);
      updateDoc(FB.myUser(), data)
        .then(() => {
          notyf.success("User switched successfully. Check your app!");
          getDoc(FB.myUser()).then((doc) => {
            console.log('after', doc.data());
          });
        })
        .catch((error) => {
          notyf.error(error.message);
        });
    },
  },
};
</script>
