<template>
  <section id="content" class="animated fadeIn pt35">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center pbn">
        <!-- Products Status Table -->
        <div class="row mn">
          <div class="col-xs-12">
            <div class="panel">
              <div class="panel-heading">
                <span class="panel-title ptn">{{ course.code }} Materials</span>
                <br /><br />
                <div class="clearfix">
                  <div
                    class="allcp-form theme-primary pull-left"
                    style="padding: 0; min-width: 80%"
                  >
                    <input
                      type="text"
                      name="search"
                      id="search"
                      class="gui-input"
                      v-model="q"
                      placeholder="Search"
                    />
                  </div>

                  <div class="pull-right">
                    <button @click="upload" class="btn btn-primary">
                      Upload Material
                    </button>
                  </div>
                </div>
              </div>
              <div class="panel-body pn mt20">
                <div class="table-responsive">
                  <table
                    class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Downloads</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="file in uploads" :key="file.id">
                        <td>-</td>
                        <td>{{ file.name }}</td>
                        <td>{{ file.extension }}</td>
                        <td colspan="2" style="text-align: center">
                          <i class="fa fa-spinner fa-spin"></i> Uploading...
                        </td>
                        <td class="action">
                          <a
                            class="btn btn-danger btn-sm"
                            href="javascript:"
                            title="Cancel"
                            @click="cancelUpload(file.id)"
                            ><i class="fa fa-close"></i
                          ></a>
                        </td>
                      </tr>
                      <tr
                        v-for="(material, index) in materials"
                        :key="material.id"
                      >
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          <input
                            v-if="editing.id === material.id"
                            v-model="editing.title"
                            @blur="saveEditing"
                            @keyup="saveEditing($event.keyCode === 13)"
                            autofocus="autofocus"
                            class="gui-input"
                          />
                          <span v-else @dblclick="editName(material)">{{
                            material.name
                          }}</span>
                        </td>
                        <td>{{ material.extension }}</td>
                        <td>{{ material.downloads }}</td>
                        <td>{{ material.date }}</td>
                        <td class="action">
                          <a
                            class="btn btn-primary btn-sm"
                            href="javascript:"
                            @click.prevent="editName(material)"
                            ><i class="fa fa-edit"></i
                          ></a>
                          <a
                            class="btn btn-success btn-sm"
                            href="javascript:"
                            @click.prevent="download(material)"
                            ><i class="fa fa-download"></i
                          ></a>
                          <a
                            class="btn btn-danger btn-sm"
                            href="javascript:"
                            @click.prevent="deleteMaterial(material)"
                            ><i class="fa fa-trash"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import {
  Timestamp,
  addDoc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import FB from "@/services/firestore";
import { fileDialog } from "file-select-dialog";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "MaterialIndex",
  created() {
    this.id = this.$route.params.id;
    const docRef = FB.dbDoc("courses", this.id);
    this.snapshots.push(
      onSnapshot(docRef, (snapshot) => {
        if (!snapshot.exists) {
          return;
        }
        this.course = { id: this.id, ...snapshot.data() };
      })
    );
    this.loadMaterials();
  },
  data() {
    return {
      init: false,
      id: "",
      q: "",
      snapshots: [],
      course: {},
      allMaterials: [],
      editing: {},
      uploads: [],
    };
  },
  methods: {
    findIndex(id) {
      for (let i = 0; i < this.uploads.length; i++) {
        if (this.uploads[i].id === id) {
          return i;
        }
      }
      return null;
    },
    cancelUpload(id) {
      const index = this.findIndex(id);
      if (index == null) return;
      const file = this.uploads[index];
      file.task.cancel();
      this.uploads.splice(index, 1);
    },
    async upload() {
      const user = this.$store.getters.getCurrentUser;

      const storage = getStorage();

      const fileList = await fileDialog({
        multiple: true,
        accept: [".pdf", ".jpg", ".jpeg", ".png", ".doc", ".docx", ".ppt"],
      });

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const fullName = file.name;
        const uuid = uuidv4();
        const fileRef = ref(
          storage,
          `materials/${user.id}/${uuid}/${fullName}`
        );
        const dotIndex = fullName.lastIndexOf(".");
        const uploadTask = uploadBytesResumable(fileRef, file);
        const data = {
          id: uuid,
          name: fullName.substr(0, dotIndex),
          extension: fullName.substr(dotIndex + 1),
          downloads: 0,
          task: uploadTask,
          progress: 0,
          path: fileRef.fullPath,
          userId: user.id,
          school: user.school,
          faculty: user.faculty,
          size: file.size,
        };
        this.uploads.push(data);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const index = this.findIndex(uuid);
            if (index == null) return;

            const currentData = this.uploads[index];
            currentData.progress = progress;
            this.$set(this.uploads, index, currentData);
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
          },
          () => {
            const index = this.findIndex(uuid);
            if (index == null) return;
            const currentData = this.uploads[index];

            addDoc(FB.db(`courses/${this.id}/materials`), {
              downloads: 0,
              name: currentData.name,
              extension: currentData.extension,
              storage: currentData.path,
              votes: 0,
              date: Timestamp.now(),
              userId: currentData.userId,
              size: currentData.size,
              school: currentData.school,
              faculty: currentData.faculty,
              authAdmin: true,
            });
            this.uploads.splice(index, 1);
          }
        );
      }
    },
    editName(material) {
      this.editing = material;
      this.editing.title = material.name;
    },
    download(material) {
      const storage = getStorage();
      getDownloadURL(ref(storage, material.storage))
        .then((url) => {
          window.open(url, "_blank").focus();
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    },
    saveEditing(process) {
      if (process === false) return;
      const current = this.editing;
      this.editing = {};

      if (current.name === current.title) {
        // Not changed
        return;
      }

      const docRef = FB.dbDoc(`courses/${this.id}/materials`, current.id);

      updateDoc(docRef, {
        name: current.title,
      });
    },
    loadMaterials() {
      let id = this.$route.params.id;
      const q = query(
        FB.db(`courses/${id}/materials`),
        orderBy("date", "desc")
      );

      this.snapshots.push(
        onSnapshot(q, (snapshot) => {
          let data = [];
          snapshot.forEach((doc) => {
            let records = doc.data();
            records.date = records.date.toDate().toDateString();
            data.push({
              id: doc.id,
              ...records,
            });
          });
          this.allMaterials = data;
        })
      );
    },
    deleteMaterial(material) {
      if (!confirm("Are you sure you want to delete this material?")) {
        return;
      }

      const docRef = FB.dbDoc(`courses/${this.id}/materials`, material.id);

      deleteDoc(docRef).then(() => {
        const storage = getStorage();
        const delRef = ref(storage, material.storage);
        deleteObject(delRef);
      });
    },
    closeSnapshot() {
      this.snapshots.forEach((snapshot) => snapshot());
    },
  },
  computed: {
    materials() {
      const query = this.q.trim().toLowerCase();
      if (query === "") {
        return this.allMaterials;
      }

      const keys = query.split(" ");

      return this.allMaterials.filter((course) => {
        for (const key of keys) {
          if (course.name.toLowerCase().includes(key)) return true;
          if (course.extension.toLowerCase().includes(key)) return true;
        }

        return false;
      });
    },
  },
  destroyed() {
    this.closeSnapshot();
  },
};
</script>

<style>
td.action a {
  display: inline-block;
  margin-right: 10px;
  margin-top: 8px;
}
</style>
