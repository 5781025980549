<template>
  <aside id="sidebar_left" class="">
    <!-- Sidebar Left Wrapper  -->
    <div class="sidebar-left-content nano-content">
      <!-- Sidebar Menu  -->
      <ul class="nav sidebar-menu">
        <router-link
          to="/"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          custom
        >
          <li
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'active router-link-exact-active',
            ]"
          >
            <a :href="href" @click="navigate">
              <span class="caret"></span>
              <span class="sb-menu-icon fa fa-dashboard"></span>
              <span class="sidebar-title">Dashboard</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/courses"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          custom
        >
          <li
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'active router-link-exact-active',
            ]"
          >
            <a :href="href" @click="navigate">
              <span class="caret"></span>
              <span class="sb-menu-icon fa fa-table"></span>
              <span class="sidebar-title">Courses</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/reps"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          custom
        >
          <li
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'active router-link-exact-active',
            ]"
          >
            <a :href="href" @click="navigate">
              <span class="caret"></span>
              <span class="sb-menu-icon fa fa-users"></span>
              <span class="sidebar-title">Class Admins</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="isBoss"
          to="/requests"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          custom
        >
          <li
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'active router-link-exact-active',
            ]"
          >
            <a :href="href" @click="navigate">
              <span class="caret"></span>
              <span class="sb-menu-icon fa fa-user-plus"></span>
              <span class="sidebar-title">Requests</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="isBoss"
          to="/requests-materials"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          custom
        >
          <li
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'active router-link-exact-active',
            ]"
          >
            <a :href="href" @click="navigate">
              <span class="caret"></span>
              <span class="sb-menu-icon fa fa-book"></span>
              <span class="sidebar-title">Material Requests</span>
            </a>
          </li>
        </router-link>
      </ul>
      <!-- /Sidebar Menu  -->
    </div>
    <!-- /Sidebar Left Wrapper  -->
  </aside>
</template>

<script>
export default {
  name: "SidebarApp",
  mounted() {
    runSideMenu();
  },
  computed: {
    isBoss() {
      return this.$store.getters.isBoss;
    },
  },
};
</script>

<style>
.sidebar-menu > li a .sidebar-title {
  margin-left: 5px;
}
</style>
