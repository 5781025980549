<template>
  <select @change="$emit('input', $event.target.value)">
    <option value="">{{ label }}</option>
    <option
      :key="'t-' + time.key"
      v-for="time in records"
      :value="time.key"
      :selected="compareTime(value, time.key)"
    >
      {{ time.title }}
    </option>
  </select>
</template>

<script>
export default {
  name: "SelectTime",
  props: ["value", "label"],
  created() {
    // generate records
    // 8am to 11 am
    for (let i = 8; i < 12; i++) {
      let t = i.toString().padStart(2, "0");

      this.add(t, "am");
    }

    this.add(12, "pm");

    // 1pm to 9pm
    for (let i = 1; i <= 9; i++) {
      let t = i.toString().padStart(2, "0");
      this.add(t, "pm");
    }
  },
  data() {
    return {
      records: [],
    };
  },
  methods: {
    compareTime(a, b) {
      if (!a || !b) return false;
      // remove spaces
      // remove padded 0 to the left
      // compare tolowercase
      return (
        a.replace(/\s/g, "").replace(/^0+/, "").toLowerCase() ===
        b.replace(/\s/g, "").replace(/^0+/, "").toLowerCase()
      );
    },
    add(t, r) {
      // o'clock
      let name = `${t}:00 ${r}`;
      this.records.push({
        key: name,
        title: name.toUpperCase(),
      });

      let name30 = `${t}:30 ${r}`;
      this.records.push({
        key: name30,
        title: name30.toUpperCase(),
      });

      let name45 = `${t}:45 ${r}`;
      this.records.push({
        key: name45,
        title: name45.toUpperCase(),
      });
    },
  },
};
</script>

<style scoped>
select {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #dddfe3;
  color: #929599;
  background-color: #fff;
  border-radius: 5px;
  letter-spacing: 0.02em;
  padding: 15px 22px 15px;
}
</style>
