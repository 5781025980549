import FB from "@/services/firestore";
import store from "@/stores/index";
import { onSnapshot, query } from "firebase/firestore";

let listener = null;

const loadReps = () => {
  if (listener != null) {
    listener();
    // reset previous
    store.commit("updateReps", []);
  }

  const q = query(FB.db("reps"));

  listener = onSnapshot(
    q,
    (querySnapshot) => {
      let reps = [];
      querySnapshot.forEach(function (doc) {
        let data = doc.data();
        data.id = doc.id;
        reps.push(data);
      });
      store.commit("updateReps", reps);
    },
    (error) => {
      console.log("----loadReps-----");
      console.log(error);
    }
  );
};

export default loadReps;
