import auth from "@/stores/auth";

import Vuex from "vuex";
import Vue from "vue";
import courses from "@/stores/courses";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    courses,
  },
});
