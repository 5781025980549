<template>
  <div class="animated fadeIn">
    <login-app v-if="!logged"></login-app>
    <template v-else>
      <header-app></header-app>
      <sidebar-app></sidebar-app>
      <section id="content_wrapper">
        <section class="content_container">
          <!-- Topbar -->
          <header id="topbar" class="breadcrumb_style_2">
            <div class="topbar-left">
              <ol class="breadcrumb">
                <li class="breadcrumb-icon breadcrumb-active">
                  <a href="#">
                    <span class="fa fa-circle-o"></span>
                  </a>
                </li>
                <li class="breadcrumb-icon breadcrumb-link">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-current-item">Dashboard</li>
              </ol>
            </div>
            <div class="topbar-right">
              <div class="ib topbar-dropdown">
                <label for="topbar-multiple" class="control-label"
                  >Reporting Period</label
                >
                <select id="topbar-multiple" class="hidden">
                  <optgroup label="Filter By:">
                    <option value="1-1">Last 30 Days</option>
                    <option value="1-2" selected="selected">
                      Last 60 Days
                    </option>
                    <option value="1-3">Last Year</option>
                  </optgroup>
                </select>
              </div>
              <div class="ml15 ib va-m" id="sidebar_right_toggle">
                <div class="navbar-btn btn-group btn-group-number mv0">
                  <button class="btn btn-sm prn pln">
                    <i class="fa fa-bar-chart fs22 text-default"></i>
                  </button>
                </div>
              </div>
            </div>
          </header>
          <router-view />
          <footer id="content-footer">
            <div class="row">
              <div class="col-md-12 text-center">
                <span class="footer-legal">© 2023 All Rights Reserved.</span>
              </div>
            </div>
          </footer>
        </section>
      </section>
    </template>
  </div>
</template>

<script>
import HeaderApp from "./components/Header";
import SidebarApp from "./components/SideBar";
import LoginApp from "./views/Login";

export default {
  name: "App",
  components: { LoginApp, HeaderApp, SidebarApp },
  computed: {
    logged() {
      return this.$store.getters.logged;
    },
  },
};
</script>
