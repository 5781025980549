<template>
  <section id="content" class="animated fadeIn pt35">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center pbn">
        <!-- Products Status Table -->
        <div class="row mn">
          <div class="col-xs-12">
            <div class="panel">
              <div class="panel-heading">
                <span class="panel-title ptn">Class Admins</span>
                <br /><br />
                <div class="clearfix">
                  <div
                    class="allcp-form theme-primary pull-left"
                    style="padding: 0; min-width: 80%"
                  >
                    <input
                      type="text"
                      name="search"
                      id="search"
                      class="gui-input"
                      v-model="q"
                      placeholder="Search"
                    />
                  </div>

                  <div class="pull-right">
                    <router-link to="reps/create" class="btn btn-primary"
                      >Add Rep
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="panel-body pn mt20">
                <div class="table-responsive">
                  <table
                    class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>Level</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(rep, index) in reps" :key="rep.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ rep.user.name }}
                        </td>
                        <td>
                          {{ rep.user.email }}
                        </td>
                        <td>{{ rep.department.name }}</td>
                        <td>
                          <span>{{ rep.level }}</span>
                        </td>
                        <td>
                          <a
                            class="btn btn-danger btn-sm"
                            href="javascript:"
                            @click.prevent="removeRep(rep.id)"
                            ><i class="fa fa-trash"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { Notyf } from "notyf";
import { getAuth } from "firebase/auth";

const notyf = new Notyf();

export default {
  name: "RepsIndex",
  data() {
    return {
      q: "",
      snapshot: null,
    };
  },
  methods: {
    async removeRep(id) {
      if (!confirm("Are you sure you want to remove this class rep?")) {
        return;
      }

      let loader = this.$loading.show();

      try {
        const res = await axios.delete(`admin/reps/${id}`, {
          headers: {
            Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`,
          },
        });
        loader.hide();
        notyf.success(res.data.message);
      } catch (error) {
        loader.hide();
        if (error.response) {
          notyf.error(error.response.data.message);
          return;
        }
        notyf.error("Failed to process request. Please try again");
      }
    },
    closeSnapshot() {
      if (this.snapshot != null) {
        this.snapshot();
        this.snapshot = null;
      }
    },
  },
  computed: {
    reps() {
      const query = this.q.trim().toLowerCase();
      if (query === "") {
        return this.allReps;
      }

      const keys = query.split(" ");

      return this.allReps.filter((rep) => {
        for (const key of keys) {
          if (rep.user.name.toLowerCase().includes(key)) return true;
          if (rep.user.email.toLowerCase().includes(key)) return true;
          if (rep.department.name.toLowerCase().includes(key)) return true;
          if (rep.level.toString().includes(key)) return true;
        }
        return false;
      });
    },
    allReps() {
      return this.$store.getters.getReps;
    },
  },
  destroyed() {
    this.closeSnapshot();
  },
};
</script>
