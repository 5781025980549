import FB from "@/services/firestore";
import store from "@/stores/index";
import { onSnapshot, orderBy, query } from "firebase/firestore";

let listener = null;

const loadCourses = () => {
  if (listener != null) {
    listener();
    // reset previous
    store.commit("updateCourses", []);
  }

  const q = query(
    FB.db("courses"),
    orderBy("semester"),
    orderBy("level"),
    orderBy("code")
  );

  listener = onSnapshot(
    q,
    (querySnapshot) => {
      let courses = [];
      querySnapshot.forEach(function (doc) {
        let data = doc.data();
        data.id = doc.id;
        courses.push(data);
      });
      store.commit("updateCourses", courses);
    },
    (error) => {
      console.log("----loadCourses-----");
      console.log(error);
    }
  );
};

export default loadCourses;
