import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login.vue";
import CoursePage from "@/views/courses/CoursePage";
import RepsIndex from "@/views/reps/RepsIndex";
import RepsPage from "@/views/reps/RepsPage";
import CourseIndex from "@/views/courses/CourseIndex";
import Dashboard from "@/views/stats/Dashboard";
import MaterialIndex from "@/views/materials/MaterialIndex";
import RequestsIndex from "@/views/requests/RequestsIndex.vue";
import MaterialsRequestsIndex from "@/views/requests-materials/MaterialsRequestsIndex.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
    },
    {
      path: "/courses",
      name: "courses.index",
      component: CourseIndex,
    },
    {
      path: "/courses/:id",
      name: "courses.show",
      component: CoursePage,
    },
    {
      path: "/materials/:id",
      name: "materials.index",
      component: MaterialIndex,
    },
    {
      path: "/requests",
      name: "requests.index",
      component: RequestsIndex,
    },
    {
      path: "/requests-materials",
      name: "requests-materials.index",
      component: MaterialsRequestsIndex,
    },
    {
      path: "/reps",
      name: "reps.index",
      component: RepsIndex,
    },
    {
      path: "/reps/create",
      name: "reps.create",
      component: RepsPage,
    },
  ],
});
