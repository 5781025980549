var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{attrs:{"id":"sidebar_left"}},[_c('div',{staticClass:"sidebar-left-content nano-content"},[_c('ul',{staticClass:"nav sidebar-menu"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
            isActive && 'router-link-active',
            isExactActive && 'active router-link-exact-active',
          ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sb-menu-icon fa fa-dashboard"}),_c('span',{staticClass:"sidebar-title"},[_vm._v("Dashboard")])])])]}}])}),_c('router-link',{attrs:{"to":"/courses","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
            isActive && 'router-link-active',
            isExactActive && 'active router-link-exact-active',
          ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sb-menu-icon fa fa-table"}),_c('span',{staticClass:"sidebar-title"},[_vm._v("Courses")])])])]}}])}),_c('router-link',{attrs:{"to":"/reps","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
            isActive && 'router-link-active',
            isExactActive && 'active router-link-exact-active',
          ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sb-menu-icon fa fa-users"}),_c('span',{staticClass:"sidebar-title"},[_vm._v("Class Admins")])])])]}}])}),(_vm.isBoss)?_c('router-link',{attrs:{"to":"/requests","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
            isActive && 'router-link-active',
            isExactActive && 'active router-link-exact-active',
          ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sb-menu-icon fa fa-user-plus"}),_c('span',{staticClass:"sidebar-title"},[_vm._v("Requests")])])])]}}],null,false,3326247890)}):_vm._e(),(_vm.isBoss)?_c('router-link',{attrs:{"to":"/requests-materials","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
            isActive && 'router-link-active',
            isExactActive && 'active router-link-exact-active',
          ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sb-menu-icon fa fa-book"}),_c('span',{staticClass:"sidebar-title"},[_vm._v("Material Requests")])])])]}}],null,false,223608150)}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }