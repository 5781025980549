import Vue from "vue";
import Loader from "./Loader.vue";
import router from "./router";
import axios from "axios";
import Loading from "vue-loading-overlay";
import vSelect from "vue-select";
import store from "./stores/index";
import { initializeApp } from "firebase/app";

import "vue-loading-overlay/dist/vue-loading.css";
import "notyf/notyf.min.css";
import "vue-select/dist/vue-select.css";
import { collection, getFirestore } from "firebase/firestore";
// Init plugin
Vue.use(Loading, { color: "red" });
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

const config = {
  apiKey: "AIzaSyBFlcTGZB4DLegNkeSPNQuHZrNX4Eqrx88",
  authDomain: "myclass-snet.firebaseapp.com",
  databaseURL: "https://myclass-snet.firebaseio.com",
  projectId: "myclass-snet",
  storageBucket: "myclass-snet.appspot.com",
  messagingSenderId: "62325039616",
  appId: "1:62325039616:web:00f3eeb09af789f9166ee5",
  measurementId: "G-72CCZK9L5J",
};
initializeApp(config);

axios.defaults.baseURL =
  "https://us-central1-myclass-snet.cloudfunctions.net/api-v1/";

new Vue({
  router,
  store,
  render: (h) => h(Loader),
}).$mount("#app");
