<template>
  <section id="content" class="animated fadeIn pt35 pb45">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center">
        <div class="mw1000 center-block">
          <form @submit.prevent="submit" method="post">
            <!-- General Information -->
            <div class="panel mb35">
              <div class="panel-heading">
                <span class="panel-title">General Information</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="allcp-form theme-primary">
                  <div class="section row mb10">
                    <label
                      for="course-code"
                      class="field-label col-sm-2 ph10 text-center"
                      >Code:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label for="course-code" class="field">
                        <input
                          type="text"
                          name="course-code"
                          id="course-code"
                          class="gui-input"
                          v-model="course.code"
                          @input="codeInput"
                          placeholder="Course Code"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div class="section row mb10">
                    <label
                      for="course-title"
                      class="field-label col-sm-2 ph10 text-center"
                      >Title:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label for="course-title" class="field">
                        <input
                          type="text"
                          name="course-title"
                          id="course-title"
                          class="gui-input"
                          v-model="course.title"
                          placeholder="Course Title"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div class="section row mb10">
                    <label
                      for="course-unit"
                      class="field-label col-sm-2 ph10 text-center"
                      >Unit:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label for="course-unit" class="field">
                        <input
                          type="number"
                          name="course-unit"
                          id="course-unit"
                          class="gui-input"
                          v-model="course.unit"
                          min="0"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div class="section row mb10">
                    <label
                      for="level-input"
                      class="field-label col-sm-2 ph10 text-center"
                      >Level:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label for="level-input" class="field">
                        <input
                          type="number"
                          name="level-input"
                          id="level-input"
                          class="gui-input"
                          v-model="course.level"
                          min="100"
                          step="100"
                          max="600"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <hr class="short alt" />
                  <div class="section row mb10">
                    <label
                      for="department-input"
                      class="field-label col-sm-2 ph10 text-center"
                      >Departments:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label class="field">
                        <multiselect
                          id="department-input"
                          v-model="courseDepartments"
                          required
                          :options="departments"
                          :hideSelected="false"
                          :close-on-select="true"
                          :preserve-search="false"
                          :multiple="true"
                          track-by="id"
                          label="name"
                        >
                        </multiselect>
                        <i class="arrow double"></i>
                      </label>
                    </div>
                  </div>
                  <div id="lectures">
                    <!--  scroll position  -->
                  </div>
                  <div class="section row mb10">
                    <label
                      for="lecturer-input"
                      class="field-label col-sm-2 ph10 text-center"
                      >Lecturers:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label class="field">
                        <multiselect
                          id="lecturer-input"
                          v-model="course.lecturers"
                          :options="lecturers"
                          :hideSelected="false"
                          :close-on-select="true"
                          tag-placeholder="Add as new lecturer"
                          :preserve-search="false"
                          :multiple="true"
                          :taggable="true"
                          @tag="addLecturer"
                        ></multiselect>
                        <i class="arrow double"></i>
                      </label>
                    </div>
                  </div>
                  <!-- /section -->
                </div>
              </div>
            </div>
            <!-- Settings -->
            <div class="panel mb35">
              <div class="panel-heading">
                <span class="panel-title">Lectures</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="allcp-form theme-primary">
                  <template v-for="(lecture, index) in course.lectures">
                    <div class="section row mb10" :key="'ll_' + index">
                      <label class="field-label col-md-2 ph10 text-center"
                        >Day:</label
                      >
                      <div class="col-md-10 ph10">
                        <label class="field select">
                          <select
                            :id="'set-location' + index"
                            :name="'set-location' + index"
                            v-model="lecture.day"
                          >
                            <option key="fs"></option>
                            <option v-for="day in days" :key="'fs_' + day">
                              {{ day }}
                            </option>
                          </select>
                          <i class="arrow double"></i>
                        </label>
                      </div>
                    </div>
                    <div class="section row mb10" :key="'ll2_' + index">
                      <label
                        :for="'input-venue' + index"
                        class="field-label col-md-2 ph10 text-center"
                        >Venue:</label
                      >
                      <div class="col-md-10 ph10">
                        <label
                          :for="'input-venue' + index"
                          class="field prepend-icon"
                        >
                          <input
                            type="text"
                            :name="'input-venue' + index"
                            :id="'input-venue' + index"
                            v-model="lecture.venue"
                            class="gui-input"
                          />
                          <span class="field-icon">
                            <i class="fa fa-map-marker"></i>
                          </span>
                        </label>
                      </div>
                    </div>
                    <br :key="'ll3_' + index" />
                    <div class="section row mb10" :key="'ll4_' + index">
                      <label
                        :for="'time-input' + index"
                        class="field-label col-md-2 ph10 text-center"
                        >Time:</label
                      >
                      <div class="col-md-10 ph10 time-row">
                        <label :for="'s-time-input' + index" class="field">
                          <select-time
                            :id="'s-time-input' + index"
                            label="Start Time"
                            v-model="lecture.start"
                          />
                        </label>
                        <span style="padding: 0 20px">to</span>
                        <label :for="'e-time-input' + index" class="field">
                          <select-time
                            :id="'e-time-input' + index"
                            label="End Time"
                            v-model="lecture.end"
                          />
                        </label>
                        <button
                          class="btn btn-danger pull-right"
                          @click.prevent="deleteLecture(index)"
                        >
                          <i class="fa fa-close"></i> Delete Lecture
                        </button>
                      </div>
                    </div>
                    <hr class="short alt" :key="'ll5_' + index" />
                  </template>
                  <div class="section row mb10">
                    <label
                      class="field-label col-md-2 ph10 text-center"
                      style="line-height: initial"
                    >
                      <button
                        class="btn btn-primary"
                        @click.prevent="addLecture"
                      >
                        <i class="fa fa-plus"></i>
                        Add
                      </button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Test -->
            <div class="panel mb35">
              <div class="panel-heading">
                <span class="panel-title">Test</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="allcp-form theme-primary">
                  <div class="section row mb25">
                    <label
                      for="test-input"
                      class="field-label col-md-2 ph10 text-center"
                      >Date</label
                    >
                    <div class="col-md-10 ph10">
                      <label for="test-input" class="field">
                        <VueCtkDateTimePicker
                          v-model="course.test.date"
                          format="YYYY-MM-DD"
                          formatted="ll"
                          id="test-input"
                          :only-date="true"
                          label="Test Date"
                        />
                      </label>
                    </div>
                  </div>

                  <div class="section row mb10">
                    <label
                      for="test-venue"
                      class="field-label col-md-2 ph10 text-center"
                      >Venue:</label
                    >
                    <div class="col-md-10 ph10">
                      <label for="test-venue" class="field prepend-icon">
                        <input
                          type="text"
                          name="test-venue"
                          id="test-venue"
                          v-model="course.test.venue"
                          class="gui-input"
                        />
                        <span class="field-icon">
                          <i class="fa fa-map-marker"></i>
                        </span>
                      </label>
                    </div>
                  </div>
                  <br />
                  <div class="section row mb10">
                    <label class="field-label col-md-2 ph10 text-center"
                      >Time:</label
                    >
                    <div class="col-md-10 ph10 time-row">
                      <label for="test-time-input" class="field">
                        <select-time
                          v-model="course.test.start"
                          id="test-time-input"
                          label="Start Time"
                        />
                      </label>
                      <span>to</span>
                      <label for="test-end-input" class="field">
                        <select-time
                          v-model="course.test.end"
                          id="test-end-input"
                          label="End Time"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Exam -->
            <div class="panel mb35">
              <div class="panel-heading">
                <span class="panel-title">Exam</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="allcp-form theme-primary">
                  <div class="section row mb25">
                    <label
                      for="test-input"
                      class="field-label col-md-2 ph10 text-center"
                      >Date</label
                    >
                    <div class="col-md-10 ph10">
                      <label for="test-input" class="field">
                        <VueCtkDateTimePicker
                          v-model="course.exam.date"
                          format="YYYY-MM-DD"
                          @input="examChanged"
                          formatted="ll"
                          id="exam-input"
                          :only-date="true"
                          label="Exam Date"
                        />
                      </label>
                    </div>
                  </div>

                  <div class="section row mb10">
                    <label
                      for="exam-venue"
                      class="field-label col-md-2 ph10 text-center"
                      >Venue:</label
                    >
                    <div class="col-md-10 ph10">
                      <label for="exam-venue" class="field prepend-icon">
                        <input
                          type="text"
                          name="exam-venue"
                          id="exam-venue"
                          @input="examChanged"
                          v-model="course.exam.venue"
                          class="gui-input"
                        />
                        <span class="field-icon">
                          <i class="fa fa-map-marker"></i>
                        </span>
                      </label>
                    </div>
                  </div>
                  <br />
                  <div class="section row mb10">
                    <label
                      for="exam-time-input"
                      class="field-label col-md-2 ph10 text-center"
                      >Time:</label
                    >
                    <div class="col-md-10 ph10 time-row">
                      <label for="exam-time-input" class="field">
                        <select-time
                          v-model="course.exam.start"
                          id="exam-time-input"
                          @input="examChanged"
                          :only-time="true"
                          label="Start Time"
                        />
                      </label>
                      <span>to</span>
                      <label for="exam-time-input" class="field">
                        <select-time
                          v-model="course.exam.end"
                          @input="examChanged"
                          id="exam-end-input"
                          label="End Time"
                        />
                      </label>
                    </div>
                  </div>
                  <div v-if="examConflictMessage">
                    <p class="text-danger text-center">
                      {{ examConflictMessage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Action -->
            <div class="panel mb35">
              <div class="panel-heading">
                <span class="panel-title">Actions after save</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="allcp-form theme-primary">
                  <div class="section row mb10">
                    <label
                      for="addNew"
                      class="field-label col-md-2 ph10 text-center"
                      >Add new course</label
                    >
                    <div class="col-md-10 ph10">
                      <input
                        type="checkbox"
                        name="addNew"
                        id="addNew"
                        style="margin-top: 15px"
                        v-model="addNew"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center">
              <button
                type="submit"
                class="btn btn-success btn-lg"
                style="min-width: 30%"
              >
                <i class="fa fa-save"></i>
                {{ this.saving ? term.action + "..." : term.name }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

import Multiselect from "vue-multiselect";
import * as moment from "moment";
import SelectTime from "@/components/Time";
import { Notyf } from "notyf";

import FB from "@/services/firestore";

import {
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const createTerm = {
  name: "Submit",
  action: "Submitting",
  create: true,
};

const updateTerm = {
  name: "Update",
  action: "Updating",
  create: false,
};

const notyf = new Notyf();

export default {
  name: "CourseCreate",
  components: { SelectTime, Multiselect, VueCtkDateTimePicker },
  async created() {
    let id = this.$route.params.id;
    if (id !== "create") {
      this.id = id;
      this.term = updateTerm;
      let loader = this.$loading.show();
      this.addNew = false;
      try {
        const docRef = FB.dbDoc("courses", id);

        let doc = await getDoc(docRef);
        loader.hide();
        if (doc.exists) {
          this.course = doc.data();
          if (!this.course.lecturers) {
            this.course.lecturers = [];
          }
          if (!this.course.lectures) {
            this.course.lectures = [];
          }
          if (!this.course.test) {
            this.course.test = {};
          }
          if (!this.course.exam) {
            this.course.exam = {};
          }
          this.before = doc.data();
          this.setDepartments();
        } else {
          // abort 404
          console.log("not found");
        }
      } catch (err) {
        loader.hide();
        console.log(err);
        alert("failed to get document");
      }
    } else {
      this.term = createTerm;
    }
  },
  data() {
    return {
      init: false,
      id: null,
      term: null,
      before: {},
      course: {
        departments: [],
        lecturers: [],
        lectures: [],
        test: {},
        exam: {},
      },
      courseDepartments: [],
      lecturers: [],
      days: days,
      saving: false,
      examConflictMessage: null,
      addNew: true,
    };
  },
  methods: {
    reset() {
      this.course.title = "";
      this.course.code = "";
      this.course.unit = "";
      this.course.lecturers = [];
      this.course.lectures = [];
      this.course.test = {};
      this.course.exam = {};
    },
    setDepartments() {
      if (this.course.departments.length > 0) {
        this.courseDepartments = this.course.departments.map((item) => {
          let id;
          if (item.id !== undefined) {
            id = item.id;
          } else {
            id = item;
          }
          return this.departments.filter((dept) => dept.id === id)[0];
        });
      }
    },
    addLecturer(tag) {
      this.course.lecturers.push(tag);
      this.lecturers.push(tag);
    },
    addLecture() {
      this.course.lectures.push({});
    },
    deleteLecture(index) {
      this.course.lectures.splice(index, 1);
    },
    setHours() {
      // Total hours in a week
      let hours = 0;
      for (let i = 0; i < this.course.lectures.length; i++) {
        let lecture = this.course.lectures[i];

        let start = moment(lecture.start, "h:mm a").hour();
        let end = moment(lecture.end, "h:mm a").hour();

        let hour = end - start;
        if (hour > 0) {
          hours += hour;
        }
      }
      this.course.hours = hours;

      // semester
      let res = this.course.code.match(/\d$/);
      this.course.semester = res % 2 === 1 ? 1 : 2;

      // department
      this.course.departments = this.courseDepartments.map((dept) => dept.id);
    },
    async save() {
      this.setHours();
      if (this.id === null) {
        // create
        return addDoc(FB.db("courses"), this.course);
      }
      await updateDoc(FB.dbDoc("courses", this.id), this.course);
      return FB.dbDoc("courses", this.id);
    },
    async validate() {
      // validate lectures
      for (let lecture of this.course.lectures) {
        if (lecture.day) {
          if (!lecture.start) {
            return `Lecture start day is missing for ${lecture.day}`;
          }

          if (!lecture.end) {
            return `Lecture end day is missing for ${lecture.day}`;
          }

          if (!lecture.venue) {
            return `Lecture venue is missing for ${lecture.day}`;
          }
        }
      }

      // Check for duplicate courses
      let exists = await getDocs(
        query(FB.db("courses"), where("code", "==", this.course.code))
      );

      if (!exists.empty && exists.docs[0].id !== this.id) {
        console.log(exists.docs[0].get("code"));
        console.log(this.course.code);
        return `${this.course.code} already exists. Please find the course and make your update.`;
      }

      // check exam date
      if (this.course.exam && this.course.exam.start) {
        let snapshot = await getDocs(
          query(FB.db("courses"), where("exam", "==", this.course.exam))
        );
        let courses = snapshot.docs;
        courses = courses.filter((c) => c.id !== this.id);
        if (courses.length > 0) {
          this.examConflictMessage =
            "This date is conflicting with " +
            courses[0].get("code") +
            " exam date";
          return false;
        }
      }

      // clean up before save
      if (this.course.test) {
        if (!this.course.test.start || !this.course.test.end) {
          this.course.test.start = null;
          this.course.test.end = null;
        }
      }

      if (this.course.exam) {
        if (!this.course.exam.start || !this.course.exam.end) {
          this.course.exam.start = null;
          this.course.exam.end = null;
        }
      }

      // force as int
      this.course.level = parseInt(this.course.level);
      this.course.semester = parseInt(this.course.semester);

      return true;
    },
    async submit() {
      this.saving = true;

      let res = await this.validate();

      if (typeof res == "string") {
        notyf.error(res);
        this.saving = false;
        if (res.includes("Lecture")) {
          location.hash = "";
          location.hash = "#lectures";
        }
        return;
      }

      if (!res) {
        this.saving = false;
        return;
      }

      try {
        const ref = await this.save();
        this.audit(ref);
        if (this.addNew) {
          const code = this.course.code;
          //  Proceed
          this.reset();
          // scroll up
          location.hash = "";
          location.hash = "#content_wrapper";
          this.id = null;
          this.term = createTerm;
          notyf.success(
            `${code} has been saved. You can now proceed to add a new course`
          );
        } else {
          await this.$router.push({ name: "courses.index" });
        }
      } catch (e) {
        console.log("failed");
        console.log(e);
      }
      this.saving = false;
    },
    codeInput() {
      this.course.code = this.course.code.replace(" ", "").toUpperCase();

      // extract level
      let res = this.course.code.match(/\d/);
      if (res !== null) {
        this.course.level = res[0] * 100;
      }
    },
    examChanged() {
      this.examConflictMessage = null;
    },
    audit(ref) {
      const user = this.$store.getters.getCurrentUser;

      // compute changes between before and course
      let changes = {};
      for (let key in this.course) {
        // dive deeper
        if (typeof this.course[key] == "object") {
          for (let k in this.course[key]) {
            // handle when before is null
            if (!this.before[key]) {
              changes[key + "." + k] = this.course[key][k];
            } else {
              if (this.course[key][k] !== this.before[key][k]) {
                changes[key + "." + k] = this.course[key][k];
              }
            }
          }
        } else {
          if (this.course[key] !== this.before[key]) {
            changes[key] = {
              before: this.before[key] || null,
              after: this.course[key],
            };
          }
        }
      }

      addDoc(FB.audits(), {
        action: this.id === null ? "create" : "update",
        ref: ref,
        changes,
        by: user,
        school: user.school,
        faculty: user.faculty,
        timestamp: serverTimestamp(),
      });
    },
  },
  watch: {
    departments(data) {
      if (this.init) return;
      this.init = true;
      if (this.course.departments.length > 0) {
        this.courseDepartments = this.course.departments.map((item) => {
          let id;
          if (item.id !== undefined) {
            id = item.id;
          } else {
            id = item;
          }
          return data.filter((dept) => dept.id === id)[0];
        });
      }
    },
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartments;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"></style>
<style>
.time-row label {
  display: inline-block !important;
}

.time-row span {
  display: inline-block !important;
  margin-left: 20px;
  margin-right: 20px;
}

.time-row .btn {
  margin-left: 20px;
  margin-top: 10px;
}
</style>
