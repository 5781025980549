<template>
  <section id="content" class="animated fadeIn pt35 pb45">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center">
        <div class="mw1000 center-block">
          <form @submit.prevent="submit" method="post">
            <!-- General Information -->
            <div class="panel mb35">
              <div class="panel-heading">
                <span class="panel-title">User Information</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="allcp-form theme-primary">
                  <div class="section row mb10">
                    <label
                      for="course-title"
                      class="field-label col-sm-2 ph10 text-center"
                      >Email:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label for="course-title" class="field">
                        <input
                          type="email"
                          name="course-title"
                          id="course-title"
                          class="gui-input"
                          v-model="rep.email"
                          placeholder="Email"
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div class="section row mb10">
                    <label
                      for="department-input"
                      class="field-label col-sm-2 ph10 text-center"
                      >Departments:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label class="field">
                        <select
                          id="department-input"
                          v-model="rep.department"
                          class="gui-input"
                          required
                          :options="departments"
                        >
                          <option></option>
                          <option
                            v-for="department in departments"
                            :value="department.id"
                            :key="department.id"
                          >
                            {{ department.name }}
                          </option>
                        </select>
                        <i class="arrow double"></i>
                      </label>
                    </div>
                  </div>

                  <div class="section row mb10">
                    <label
                      for="level-input"
                      class="field-label col-sm-2 ph10 text-center"
                      >Level:</label
                    >
                    <div class="col-sm-10 ph10">
                      <label for="level-input" class="field">
                        <input
                          type="number"
                          name="level-input"
                          id="level-input"
                          class="gui-input"
                          placeholder="Level"
                          v-model="rep.level"
                          min="100"
                          step="100"
                          max="600"
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <!-- /section -->
                </div>
              </div>
            </div>
            <div class="text-center">
              <button
                type="submit"
                class="btn btn-success btn-lg"
                style="min-width: 30%"
              >
                <i class="fa fa-save"></i>
                {{ this.saving ? "Adding..." : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { Notyf } from "notyf";
import { getAuth } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import FB from "@/services/firestore";

const notyf = new Notyf();

export default {
  name: "RepsPage",
  created() {
    this.loadDepartments();
  },
  data() {
    return {
      snapshot: null,
      id: null,
      rep: {
        email: "",
        department: "",
        level: "",
      },
      departments: [],
      saving: false,
    };
  },
  methods: {
    async submit() {
      this.saving = true;
      try {
        const res = await axios.post("admin/reps", this.rep, {
          headers: {
            Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`,
          },
        });
        this.saving = true;
        notyf.success(res.data.message);
        await this.$router.push({ path: "/reps" });
      } catch (error) {
        this.saving = false;
        if (error.response) {
          notyf.error(error.response.data.message);
          return;
        }
        notyf.error("Failed to process form");
      }
    },
    loadDepartments() {
      this.snapshot = onSnapshot(FB.db("departments"), (snapshot) => {
        let depts = [];
        for (const doc of snapshot.docs) {
          const record = {
            id: doc.id,
            ...doc.data(),
          };
          depts.push(record);
        }
        this.departments = depts;
      });
    },
  },
  destroyed() {
    if (this.snapshot !== null) this.snapshot();
  },
};
</script>
