var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"animated fadeIn pt35",attrs:{"id":"content"}},[_c('div',{staticClass:"table-layout"},[_c('div',{staticClass:"chute chute-center pbn"},[_c('div',{staticClass:"row mn"},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body pn mt20"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"},[_vm._m(1),_c('tbody',_vm._l((_vm.requests),function(request,index){return _c('RequestMaterialItem',{key:request.id,attrs:{"request":request,"index":index,"classes":{
                        'bg-info': _vm.user.current === request.id,
                        'bg-danger':
                          request.admin && request.admin.id !== _vm.user.id,
                        'bg-warning':
                          request.admin &&
                          request.admin.id === _vm.user.id &&
                          _vm.user.current !== request.id,
                      }}})}),1)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading"},[_c('span',{staticClass:"panel-title ptn"},[_vm._v("Requests")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Attachment")]),_c('th',[_vm._v("School")]),_c('th',[_vm._v("Course")]),_c('th',[_vm._v("Level")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Actions")])])])
}]

export { render, staticRenderFns }