<template>
  <section id="content" class="animated fadeIn pt35">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center pbn">
        <div class="row quick-links">
          <div class="greeting-field clearfix">
            <span class="pull-left"
              >Welcome back, {{ user.name || "Admin" }}!</span
            >
            <span class="pull-right">{{ facultyName }} Faculty</span>
          </div>
          <div class="row mn" v-if="user.super">
            <div class="col-xs-12">
              <div class="panel">
                <div class="panel-heading clearfix">
                  <span class="panel-title ptn">Change Faculty</span>
                </div>
                <div class="panel-body pn mt20">
                  <div>
                    <v-select
                      :options="faculties"
                      :value="selectedFaculty"
                      @input="setFaculty"
                    ></v-select>
                  </div>

                  <div class="clearfix mt10">
                    <button
                      class="btn btn-primary pull-right"
                      @click="saveFaculty"
                    >
                      Save Faculty
                    </button>
                  </div>

                  <div class="panel-heading mt10 mb5">
                    <span class="panel-title ptn"
                      >Departments in
                      {{
                        (selectedFaculty && selectedFaculty.label) || ""
                      }}:</span
                    >
                  </div>
                  <div class="table-responsive">
                    <table
                      id="f-depts"
                      class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Programs</th>
                        </tr>
                      </thead>
                      <tbody id="depts">
                        <department-row
                          :key="'dept-' + record.id"
                          v-for="record in parentDepartments"
                          :record="record"
                          :programs="programs(record.id)"
                        >
                        </department-row>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-3">
            <router-link to="/courses">
              <div class="panel panel-tile info-block">
                <div class="panel-body">
                  <div class="info-block-top">
                    <i class="icon-tool"></i>
                    <div class="title">Courses</div>
                  </div>
                  <div class="info-block-middle text-center">
                    <h2>{{ totalCourses }}</h2>
                  </div>
                  <div class="info-block-bottom text-center">
                    <h6>Courses</h6>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-3">
            <a href="#joined">
              <div class="panel panel-tile info-block">
                <div class="panel-body">
                  <div class="info-block-top">
                    <i class="icon-social"></i>
                    <div class="title">Total Users</div>
                  </div>
                  <div class="info-block-middle text-center">
                    <h2>{{ stats.total }}</h2>
                  </div>
                  <div class="info-block-bottom text-center">
                    <h6>Users</h6>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-3">
            <a href="#joined">
              <div class="panel panel-tile info-block">
                <div class="panel-body">
                  <div class="info-block-top">
                    <i class="icon-transport"></i>
                    <div class="title">Registered Today</div>
                  </div>
                  <div class="info-block-middle text-center">
                    <h2>{{ stats.today }}</h2>
                  </div>
                  <div class="info-block-bottom text-center">
                    <h6>Registered Today</h6>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-6 col-md-3 col-xl-3">
            <router-link to="reps">
              <div class="panel panel-tile info-block">
                <div class="panel-body">
                  <div class="info-block-top">
                    <i class="icon-check"></i>
                    <div class="title">Class Reps</div>
                  </div>
                  <div class="info-block-middle text-center">
                    <h2>{{ totalReps }}</h2>
                  </div>
                  <div class="info-block-bottom text-center">
                    <h6>Orders</h6>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Products Status Table -->
        <div class="row mn">
          <div class="col-xs-12">
            <div class="panel">
              <div class="panel-heading clearfix">
                <span class="panel-title ptn pull-left"
                  >How many students have joined in the faculty?</span
                >
                <button
                  class="btn btn-success pull-right"
                  @click.prevent="refresh"
                  title="Refresh"
                >
                  <i class="fa fa-refresh"></i>
                </button>
              </div>
              <div class="panel-body pn mt20">
                <div class="table-responsive">
                  <table
                    class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Department</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody id="joined">
                      <tr
                        v-for="(record, index) in stats.data"
                        :key="'record-' + index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ department(record.department) }}
                        </td>
                        <td>
                          {{ record.total }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import FB from "@/services/firestore";
import { onSnapshot, doc, updateDoc, getFirestore } from "firebase/firestore";
import { Notyf } from "notyf";
import DepartmentRow from "@/views/stats/components/DepartmentRow";

const notyf = new Notyf();

export default {
  components: { DepartmentRow },
  name: "RepsIndex",
  created() {
    if (this.user.super) {
      this.superSetup();
    }
  },
  data() {
    return {
      faculties: [],
      fDepartments: [],
      fListener: null,
      dListener: null,
      selectedFaculty: null,
    };
  },
  methods: {
    superSetup() {
      this.fListener = onSnapshot(FB.dbFaculties(), (snapshot) => {
        let fId;
        if (this.selectedFaculty === null) {
          fId = this.facultyId;
        }

        let data = [];
        for (const doc of snapshot.docs) {
          const record = {
            code: doc.id,
            label: doc.get("name"),
          };
          data.push(record);

          if (fId === doc.id) {
            this.selectedFaculty = record;
            this.setDepartments();
          }
        }
        this.faculties = data;
      });
    },
    setDepartments() {
      if (this.selectedFaculty === null) return;
      this.fDepartments = [];

      if (this.dListener != null) {
        this.dListener();
      }

      this.dListener = onSnapshot(
        FB.dbFaculties(`${this.selectedFaculty.code}/departments`),
        (snapshot) => {
          let data = [];
          for (const doc of snapshot.docs) {
            const record = {
              id: doc.id,
              code: doc.id,
              label: doc.get("name"),
              parentId: doc.get("parentId"),
              ref: doc.ref,
            };
            data.push(record);
          }
          this.fDepartments = data;
        }
      );
    },
    setFaculty(value) {
      this.selectedFaculty = value;
      this.setDepartments();
    },
    saveFaculty() {
      if (this.selectedFaculty == null) return;

      const ref = doc(getFirestore(), "auth", this.user.phoneNumber);

      updateDoc(ref, {
        faculty: this.selectedFaculty.code,
      })
        .then(() => {
          notyf.success("Faculty change successful!");
        })
        .catch((e) => {
          console.log("failure man");
          console.log(e);
        });
    },
    department(id) {
      if (!id) return "";
      const depts = this.departments.filter((d) => d.id === id);
      if (depts.length > 0) {
        return depts[0]["name"];
      }
      return id;
    },
    programs(departmentId) {
      return this.fDepartments.filter((d) => d.parentId === departmentId);
    },
    refresh() {
      this.$store.dispatch("loadRecords");
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    facultyName() {
      let id = this.facultyId;
      const res = this.faculties.find((f) => f.code === id);

      if (res) {
        return res.label;
      }
      return id[0].toUpperCase() + id.substr(1);
    },
    facultyId() {
      return this.$store.getters.getFacultyId;
    },
    departments() {
      return this.$store.getters.getDepartments;
    },
    parentDepartments() {
      return this.fDepartments.filter((d) => !d.parentId);
    },
    stats() {
      return this.$store.getters.getStats;
    },
    totalCourses() {
      return this.$store.getters.getCourses.length;
    },
    totalReps() {
      return this.$store.getters.getReps.length;
    },
  },
  destroyed() {
    if (this.fListener != null) {
      this.fListener();
    }

    if (this.dListener != null) {
      this.dListener();
    }
  },
};
</script>

<style>
#f-depts th,
#f-depts td {
  text-align: left;
}
</style>
