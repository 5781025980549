import FB from "@/services/firestore";
import {
  serverTimestamp,
  updateDoc,
  addDoc,
  getDocs,
} from "firebase/firestore";

export default {
  state: {
    auth: null,
    user: {
      id: "",
      school: "",
      faculty: "",
      phoneNumber: "",
      super: false,
      boss: false,
      current: "",
      userId: "",
    },
  },
  mutations: {
    login(state, user) {
      state.auth = true;
      state.user.phoneNumber = user.phoneNumber;
    },
    updateUser(state, doc) {
      state.user.school = doc.get("school");
      state.user.faculty = doc.get("faculty");
      state.user.name = doc.get("name");
      state.user.super = doc.get("super") || false;
      state.user.boss = doc.get("boss") || false;
      state.user.id = doc.get("userId") || "";
      state.user.current = doc.get("current") || "";
      state.user.userId = doc.get("userId") || "";
    },
    logout(state) {
      state.auth = false;
    },
  },
  actions: {
    storeDeviceToken(state, token) {
      getDocs(FB.tokenQuery(token)).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          updateDoc(querySnapshot.docs[0].ref, {
            lastSeen: serverTimestamp(),
          });
        } else {
          addDoc(FB.deviceTokens(), {
            token: token,
            createdAt: serverTimestamp(),
            lastSeen: serverTimestamp(),
          });
        }
      });
    },
  },
  getters: {
    logged(state) {
      return !!state.auth;
    },
    super(state) {
      return state.user.super;
    },
    isBoss(state) {
      return !!state.user.boss;
    },
    getCurrentUser(state) {
      return state.user;
    },
    getFacultyId(state) {
      return state.user.faculty;
    },
    getPath(state) {
      const school = state.user.school;
      let faculty = state.user.faculty;

      return `schools/${school}/faculties/${faculty}`;
    },
    getInitializing(state) {
      return state.auth === null;
    },
  },
};
