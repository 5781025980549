import { render, staticRenderFns } from "./CoursePage.vue?vue&type=template&id=74dd4938"
import script from "./CoursePage.vue?vue&type=script&lang=js"
export * from "./CoursePage.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./CoursePage.vue?vue&type=style&index=2&id=74dd4938&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports