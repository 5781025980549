<template>
  <td :title="user.department">
    {{ name }}
  </td>
</template>

<script>
export default {
  name: "RequestDepartment",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  computed: {
    name() {
      return this.user.departmentData.name || this.user.department;
    },
  },
};
</script>
