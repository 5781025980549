var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"animated fadeIn pt35",attrs:{"id":"content"}},[_c('div',{staticClass:"table-layout"},[_c('div',{staticClass:"chute chute-center pbn"},[_c('div',{staticClass:"row mn"},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body pn mt20"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"},[_vm._m(1),_c('tbody',_vm._l((_vm.requests),function(request,index){return _c('RequestItem',{key:request.id,attrs:{"request":request,"index":index,"classes":{
                        'bg-info': _vm.user.current === request.id,
                        'bg-danger':
                          request.admin && request.admin.id !== _vm.user.id,
                        'bg-warning':
                          request.admin &&
                          request.admin.id === _vm.user.id &&
                          _vm.user.current !== request.id,
                      }}})}),1)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading"},[_c('span',{staticClass:"panel-title ptn"},[_vm._v("Requests")]),_c('div',{staticClass:"panel-content"},[_c('p',[_vm._v("Actions:")]),_c('div',[_c('a',{staticClass:"btn btn-success",attrs:{"href":"javascript:"}},[_c('i',{staticClass:"fa fa-check"})]),_vm._v(" - Mark as completed ")]),_c('div',[_c('a',{staticClass:"btn btn-warning",attrs:{"href":"javascript:"}},[_c('i',{staticClass:"fa fa-refresh"})]),_vm._v(" - Select request to work on. It will switch your faculty to the user's faculty. ")]),_c('div',[_c('a',{staticClass:"btn btn-danger",attrs:{"href":"javascript:"}},[_c('i',{staticClass:"fa fa-trash"})]),_vm._v(" - Ignore and notify user to reupload ")]),_c('p',[_vm._v("Color codes:")]),_c('div',{staticClass:"re-info"},[_c('div',{staticClass:"cl bg-danger"}),_c('span',{},[_vm._v(" - means another admin is working on it")])]),_c('div',{staticClass:"re-info"},[_c('div',{staticClass:"cl bg-info"}),_c('span',{},[_vm._v(" - means you are currently working on it")])]),_c('div',{staticClass:"re-info"},[_c('div',{staticClass:"cl bg-warning"}),_c('span',{},[_vm._v(" - means items you have previously selected")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Comment")]),_c('th',[_vm._v("Attachment")]),_c('th',[_vm._v("School")]),_c('th',[_vm._v("Department")]),_c('th',[_vm._v("Level")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Actions")])])])
}]

export { render, staticRenderFns }