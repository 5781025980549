<style>
.panel-content div {
  margin: 10px 0;
}

.panel-content p {
  font-weight: bold;
  font-style: italic;
}

.panel-content .cl {
  width: 100px;
  height: 20px;
  margin: 0 10px 0 0;
}

.re-info {
  display: flex;
  align-items: center;
}
</style>
<template>
  <section id="content" class="animated fadeIn pt35">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center pbn">
        <!-- Products Status Table -->
        <div class="row mn">
          <div class="col-xs-12">
            <div class="panel">
              <div class="panel-heading">
                <span class="panel-title ptn">Requests</span>
              </div>
              <div class="panel-body pn mt20">
                <div class="table-responsive">
                  <table
                    class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Attachment</th>
                        <th>School</th>
                        <th>Course</th>
                        <th>Level</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <RequestMaterialItem
                        v-for="(request, index) in requests"
                        :request="request"
                        :index="index"
                        :key="request.id"
                        :classes="{
                          'bg-info': user.current === request.id,
                          'bg-danger':
                            request.admin && request.admin.id !== user.id,
                          'bg-warning':
                            request.admin &&
                            request.admin.id === user.id &&
                            user.current !== request.id,
                        }"
                      >
                      </RequestMaterialItem>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import { Notyf } from "notyf";
import FB from "@/services/firestore";

import { onSnapshot, doc } from "firebase/firestore";
import RequestMaterialItem from "@/views/requests-materials/components/RequestMaterialItem.vue";

const notyf = new Notyf();

export default {
  name: "MaterialsRequestsIndex",
  components: { RequestMaterialItem },
  created() {
    this.snapshot = onSnapshot(FB.materialRequests(), async (snapshot) => {
      let data = [];
      for (let snap of snapshot.docs) {
        let records = snap.data();

        if (records.completed || records.ignored) {
          continue;
        }

        const date = records.date.toDate();

        const options = {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };

        records.createdAt = date.toLocaleString("en-US", options);

        data.push({
          id: snap.id,
          ref: snap.ref,
          ...records,
        });
      }

      this.requests = data;
    });
  },
  data() {
    return {
      q: "",
      snapshot: null,
      requests: [],
    };
  },
  methods: {
    closeSnapshot() {
      if (this.snapshot != null) {
        this.snapshot();
        this.snapshot = null;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },

  destroyed() {
    this.closeSnapshot();
  },
};
</script>
