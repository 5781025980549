<template>
  <header class="navbar navbar-fixed-top bg-system phn">
    <div class="navbar-logo-wrapper">
      <div class="logo-widget">
        <div class="media">
          <router-link class="logo-image" to="/"></router-link>
        </div>
      </div>
    </div>
    <span
      id="sidebar_top_toggle"
      class="ad ad-lines navbar-nav navbar-left showing-sm"
    ></span>
    <ul class="nav navbar-nav navbar-left">
      <li class="dropdown dropdown-fuse hidden-xs">
        <div class="navbar-btn btn-group phn">
          <button
            class="btn-hover-effects dropdown-toggle btn bg-light"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="fa fa-chevron-down"></span>
          </button>
          <ul class="dropdown-menu" role="menu">
            <li><router-link to="/">Dashboard</router-link></li>
            <li><router-link to="/courses">Courses</router-link></li>
            <li><router-link to="/reps">Class Reps</router-link></li>
          </ul>
        </div>
      </li>
      <li class="hidden-xs">
        <div class="navbar-btn btn-group">
          <button
            class="btn-hover-effects navbar-fullscreen toggle-active btn si-icons si-icons-default"
          >
            <span class="fa fa-arrows-alt"></span>
          </button>
        </div>
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right darker mn pv10">
      <li class="dropdown dropdown-fuse navbar-user">
        <a href="#" class="dropdown-toggle mln" data-toggle="dropdown">
          <img src="./../assets/admin.png" alt="avatar" />
          <span class="hidden-xs">
            <span class="name">{{ user.name || "Admin" }}</span>
          </span>
          <span class="fa fa-caret-down hidden-xs"></span>
        </a>
        <ul class="dropdown-menu list-group keep-dropdown w230" role="menu">
          <li class="dropdown-footer text-center">
            <button @click="logout" class="btn btn-warning">logout</button>
          </li>
        </ul>
      </li>
    </ul>
  </header>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "HeaderApp",
  mounted() {
    window.runHeader();
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
};
</script>
