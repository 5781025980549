<template>
  <div id="loader" v-if="initializing">
    <i class="fa fa-spinner fa-spin fa-5x"></i>
  </div>
  <div id="main" v-else>
    <app></app>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import App from "./App";

export default {
  name: "Loader",
  components: { App },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getFirestore();

        this.unsubAuth = onSnapshot(
          doc(db, "auth", user.phoneNumber),
          (doc) => {
            if (!doc.exists) {
              alert("You are not authorized to access this page");
              this.$store.commit("logout");
              return;
            }

            const before = this.$store.getters.getCurrentUser.faculty;

            if (before.length < 1) {
              // just logging int
              this.$store.commit("login", user);
            }

            this.$store.commit("updateUser", doc);
            const after = this.$store.getters.getCurrentUser.faculty;

            if (!this.dirty || before !== after) {
              this.$store.dispatch("setUp");
              this.dirty = true;
            }
          }
        );
      } else {
        this.$store.commit("logout");
      }
    });
  },
  data() {
    return {
      dirty: false,
      unsubAuth: null,
    };
  },
  computed: {
    initializing() {
      return this.$store.getters.getInitializing;
    },
  },
  destroyed() {
    if (this.unsubAuth !== null) {
      this.unsubAuth();
    }
  },
};
</script>

<style>
#loader {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: white;
  text-align: center;
  z-index: 2000;
}

#loader i {
  position: relative;
  top: 47%;
}
</style>
