<template>
  <section id="content" class="animated fadeIn pt35">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center pbn">
        <!-- Products Status Table -->
        <div class="row mn">
          <div class="col-xs-12">
            <div class="panel">
              <div class="panel-heading">
                <span class="panel-title ptn" v-if="!user"
                  >Course List<br
                /></span>
                <div v-else>
                  <span class="panel-title ptn">User Courses</span>
                  <br />
                  <span class="panel-subtitle">{{
                    departmentNameFromId(user.department)
                  }}</span>
                </div>
                <br />
                <div class="row">
                  <div class="col-sm-6" style="padding-left: 0">
                    <select v-model="level" class="form-control">
                      <option value="0">All Levels</option>
                      <option
                        :key="'sl' + lv"
                        v-for="lv in [100, 200, 300, 400, 500, 600]"
                        :selected="lv === level"
                        :value="lv"
                      >
                        {{ lv }} Level
                      </option>
                    </select>
                  </div>

                  <div class="col-sm-6" style="padding-right: 0">
                    <select v-model="semester" class="form-control">
                      <option value="0">All Semesters</option>
                      <option
                        :key="'ss' + se"
                        v-for="se in [1, 2]"
                        :selected="se === semester"
                        :value="se"
                      >
                        {{ se === 1 ? "First" : "Second" }} Semester
                      </option>
                    </select>
                  </div>
                </div>
                <br />
                <div class="clearfix">
                  <div
                    class="allcp-form theme-primary pull-left"
                    style="padding: 0; min-width: 80%"
                  >
                    <input
                      type="text"
                      name="search"
                      id="search"
                      class="gui-input"
                      v-model="q"
                      placeholder="Search"
                    />
                  </div>

                  <div class="pull-right">
                    <router-link
                      :to="{ name: 'courses.show', params: { id: 'create' } }"
                      class="btn btn-primary"
                      >Create Course
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="panel-body pn mt20">
                <div class="table-responsive">
                  <table
                    class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                  >
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Title</th>
                        <th>Unit</th>
                        <th>Level</th>
                        <th>Department</th>
                        <th>Hours (Weekly)</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="course in courses" :key="course.id">
                        <td>
                          {{ course.code }}
                        </td>
                        <td>
                          {{ course.title }}
                        </td>
                        <td>{{ course.unit }}</td>
                        <td>
                          <span>{{ course.level }}</span>
                        </td>
                        <td :title="department(course, true)">
                          <span>{{ department(course) }}</span>
                        </td>
                        <td>
                          <span>{{ course.hours ?? 0 }}</span>
                        </td>
                        <td class="action">
                          <router-link
                            :to="{
                              name: 'courses.show',
                              params: { id: course.id },
                            }"
                            class="btn btn-primary btn-sm"
                            ><i class="fa fa-edit"></i>
                          </router-link>
                          <router-link
                            class="btn btn-warning btn-sm"
                            :to="{
                              name: 'materials.index',
                              params: { id: course.id },
                            }"
                            ><i class="fa fa-book"></i
                          ></router-link>
                          <a
                            class="btn btn-danger btn-sm"
                            href="javascript:"
                            @click.prevent="deleteCourse(course.id)"
                            ><i class="fa fa-trash"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import { getDoc, deleteDoc } from "firebase/firestore";
import FB from "@/services/firestore";

export default {
  name: "courses",
  created() {
    // userId from location query
    const userId = this.$route.query.userId;
    if (userId) {
      // users/userId
      getDoc(FB.user(userId)).then((doc) => {
        if (doc.exists()) {
          this.user = doc.data();
          this.level = this.user.level;
          this.semester = this.user.semester;
        }
      });
    }
  },
  data() {
    return {
      init: false,
      q: "",
      snapshot: null,
      user: null,
      level: 0,
      semester: 0,
    };
  },
  methods: {
    departmentNameFromId(id) {
      let department = this.departments.filter((dept) => dept.id === id);
      if (department.length) {
        return department[0].name + " Department";
      }
      return "You need to switch to the user's faculty";
    },
    department(course, all) {
      if (!course.departments) {
        return "";
      }
      let depts = course.departments
        .map((id) => {
          let department = this.departments.filter((dept) => dept.id === id);
          if (department.length) {
            return department[0].name.split(" ")[0];
          }
          return "";
        })
        .join(", ");
      if (all === true) {
        return depts;
      }
      let name = depts.substr(0, 30);
      if (depts.length > 30) {
        name += "...";
      }
      return name;
    },
    deleteCourse(id) {
      if (!confirm("Are you sure you want to delete this course?")) {
        return;
      }
      deleteDoc(FB.dbDoc("courses", id));
    },
    closeSnapshot() {
      if (this.snapshot != null) {
        this.snapshot();
        this.snapshot = null;
      }
    },
  },
  computed: {
    courses() {
      const query = this.q.trim().toLowerCase();
      if (query === "") {
        return this.allCourses;
      }

      const keys = query.split(" ");

      const depts = this.departments.filter((dept) => {
        for (const key of keys) {
          if (dept.name.toLowerCase().includes(key)) return true;
        }
        return false;
      });

      const deptMap = {};
      depts.forEach((dept) => (deptMap[dept.id] = true));

      return this.allCourses.filter((course) => {
        for (const key of keys) {
          if (course.code.toLowerCase().includes(key)) return true;
          if (course.title.toLowerCase().includes(key)) return true;
          if (course.hours && course.hours.toString().includes(key)) return true;
        }

        for (let i = 0; i < course.departments.length; i++) {
          if (deptMap[course.departments[i]]) return true;
        }

        return false;
      });
    },
    allCourses() {
      let data = this.$store.getters.getCourses;
      if (this.user) {
        data = data.filter((course) => {
          return course.departments.includes(this.user.department);
        });
      }
      if (this.level) {
        data = data.filter((course) => {
          return course.level === this.level;
        });
      }
      if (this.semester) {
        data = data.filter((course) => {
          return course.semester === this.semester;
        });
      }
      return data;
    },
    departments() {
      return this.$store.getters.getDepartments;
    },
  },
  destroyed() {
    this.closeSnapshot();
  },
};
</script>

<style>
td.action a {
  display: inline-block;
  margin-right: 10px;
  margin-top: 8px;
}
</style>
