<style>
.panel-content div {
  margin: 10px 0;
}

.panel-content p {
  font-weight: bold;
  font-style: italic;
}

.panel-content .cl {
  width: 100px;
  height: 20px;
  margin: 0 10px 0 0;
}

.re-info {
  display: flex;
  align-items: center;
}
</style>
<template>
  <section id="content" class="animated fadeIn pt35">
    <div class="table-layout">
      <!-- Column Center -->
      <div class="chute chute-center pbn">
        <!-- Products Status Table -->
        <div class="row mn">
          <div class="col-xs-12">
            <div class="panel">
              <div class="panel-heading">
                <span class="panel-title ptn">Requests</span>

                <div class="panel-content">
                  <p>Actions:</p>

                  <div>
                    <a class="btn btn-success" href="javascript:"
                      ><i class="fa fa-check"></i
                    ></a>
                    - Mark as completed
                  </div>

                  <div>
                    <a class="btn btn-warning" href="javascript:"
                      ><i class="fa fa-refresh"></i
                    ></a>
                    - Select request to work on. It will switch your faculty to
                    the user's faculty.
                  </div>

                  <div>
                    <a class="btn btn-danger" href="javascript:"
                      ><i class="fa fa-trash"></i
                    ></a>
                    - Ignore and notify user to reupload
                  </div>

                  <p>Color codes:</p>
                  <div class="re-info">
                    <div class="cl bg-danger"></div>
                    <span class="">
                      - means another admin is working on it</span
                    >
                  </div>

                  <div class="re-info">
                    <div class="cl bg-info"></div>
                    <span class="">
                      - means you are currently working on it</span
                    >
                  </div>

                  <div class="re-info">
                    <div class="cl bg-warning"></div>
                    <span class="">
                      - means items you have previously selected</span
                    >
                  </div>
                </div>
              </div>
              <div class="panel-body pn mt20">
                <div class="table-responsive">
                  <table
                    class="table allcp-form theme-warning tc-checkbox-1 table-style-2 btn-gradient-grey fs13"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Comment</th>
                        <th>Attachment</th>
                        <th>School</th>
                        <th>Department</th>
                        <th>Level</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <RequestItem
                        v-for="(request, index) in requests"
                        :request="request"
                        :index="index"
                        :key="request.id"
                        :classes="{
                          'bg-info': user.current === request.id,
                          'bg-danger':
                            request.admin && request.admin.id !== user.id,
                          'bg-warning':
                            request.admin &&
                            request.admin.id === user.id &&
                            user.current !== request.id,
                        }"
                      >
                      </RequestItem>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Column Center -->
    </div>
  </section>
</template>

<script>
import { Notyf } from "notyf";
import FB from "@/services/firestore";

import { onSnapshot, doc } from "firebase/firestore";
import RequestItem from "@/views/requests/components/RequestItem.vue";

const notyf = new Notyf();

export default {
  name: "RequestsIndex",
  components: { RequestItem },
  created() {
    this.snapshot = onSnapshot(FB.requests(), async (snapshot) => {
      let data = [];
      for (let snap of snapshot.docs) {
        let records = snap.data();

        if (records.completed || records.ignored) {
          continue;
        }

        const date = records.createdAt.toDate();

        const options = {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };

        records.createdAt = date.toLocaleString("en-US", options);

        data.push({
          id: snap.id,
          ...records,
        });
      }

      this.requests = data;
    });
  },
  data() {
    return {
      q: "",
      snapshot: null,
      requests: [],
    };
  },
  methods: {
    closeSnapshot() {
      if (this.snapshot != null) {
        this.snapshot();
        this.snapshot = null;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },

  destroyed() {
    this.closeSnapshot();
  },
};
</script>
