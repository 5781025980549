<template>
  <tr>
    <td>
      {{ record.code }}
    </td>
    <td>
      {{ record.label }}

      <button
        class="btn btn-danger btn-xs"
        v-if="isEditing"
        @click.prevent="toggleEdit"
      >
        <i class="fa fa-times"></i>
      </button>
      <button class="btn btn-primary btn-xs" v-else @click.prevent="toggleEdit">
        <i class="fa fa-plus"></i>
      </button>

      <div class="clearfix mt5" v-if="isEditing">
        <input
          style="width: 200px; display: inline-block"
          type="text"
          class="form-control"
          placeholder="Enter program name"
          v-model="name"
        />
        <button
          class="btn btn-primary btn-xs"
          style="margin-left: 10px"
          @click.prevent="save"
        >
          Save
        </button>
      </div>
    </td>
    <td>{{ programNames }}</td>
  </tr>
</template>

<script>
import { addDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { Notyf } from "notyf";

const notyf = new Notyf();

export default {
  name: "DepartmentRow",
  props: {
    record: {
      type: Object,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      name: "",
    };
  },
  computed: {
    programNames() {
      return this.programs.map((p) => p.label).join(", ");
    },
  },
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    async save() {
      if (!this.name) {
        return;
      }

      await addDoc(this.record.ref.parent, {
        parentId: this.record.id,
        name: this.name,
        createdAt: serverTimestamp(),
        show: true,
      });

      notyf.success(`Program saved!`);

      this.name = "";
      this.isEditing = false;

      return updateDoc(this.record.ref, {
        hasPrograms: true,
        updatedAt: serverTimestamp(),
      });
    },
  },
};
</script>
